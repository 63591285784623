import { createLogger } from '@/modules/logging/logger';
import { configureSentryUserMetadata } from '@/modules/sentry/universal';
import { userSessionContext } from '@/modules/userSession/userSessionContext';
import * as Sentry from '@sentry/node';
import React from 'react';
import { MultiversalAppBootstrapPageProps } from '../types/MultiversalAppBootstrapPageProps';
import { MultiversalAppBootstrapProps } from '../types/MultiversalAppBootstrapProps';
import { MultiversalPageProps } from '../types/MultiversalPageProps';
import { OnlyServerPageProps } from '../types/OnlyServerPageProps';
import { helveticaDisplayNow, solidGrooves } from 'fonts/fonts';
import { MantineProvider, createTheme } from '@mantine/core';

const fileLabel = 'app/components/ServerPageBootstrap';
const logger = createLogger({
  fileLabel,
});

export type ServerPageBootstrapProps = MultiversalAppBootstrapProps<
  MultiversalPageProps & MultiversalAppBootstrapPageProps
>;

/**
 * Bootstraps the page, only when rendered on the server
 *
 * @param props
 */
const ServerPageBootstrap = (props: ServerPageBootstrapProps): JSX.Element => {
  const { Component, err } = props;
  // When the page is served by the server, some server-only properties are available
  const pageProps = props.pageProps as unknown as MultiversalPageProps<OnlyServerPageProps>;
  const injectedPageProps: MultiversalPageProps<OnlyServerPageProps> = {
    ...pageProps,
  };
  const { userSession } = pageProps;

  // Configure Sentry user and track navigation through breadcrumb
  configureSentryUserMetadata(userSession);
  Sentry.addBreadcrumb({
    // See https://docs.sentry.io/enriching-error-data/breadcrumbs
    category: fileLabel,
    message: `Rendering ${fileLabel}`,
    level: Sentry.Severity.Debug,
  });
  const mantineTheme = createTheme({
    fontFamily: 'var(--font-helvetica-display)',
  });

  return (
    <userSessionContext.Provider value={{ ...userSession }}>
      <MantineProvider theme={mantineTheme}>
        <Component
          className={`${helveticaDisplayNow.variable} ${solidGrooves.variable}`}
          {...injectedPageProps}
          // @ts-ignore
          error={err}
        />
      </MantineProvider>
    </userSessionContext.Provider>
  );
};

export default ServerPageBootstrap;
