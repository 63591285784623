import { GA_TRACKING_ID } from '@/constants/env';
import { EventCartProps } from '../data/types';

declare const window: any;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (window) {
    window.gtag?.('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const trackEvent = ({
  action,
  category,
  label,
  value,
  non_interaction = true,
  currency,
}: {
  action?: string;
  category: string;
  label: string;
  value?: number;
  non_interaction?: boolean;
  currency?: 'USD';
}) => {
  if (window) {
    window.gtag?.('event', action, {
      event_category: category,
      event_label: label,
      value: value,
      non_interaction,
      currency,
    });
  }
};

export const purchaseEvent = ({
  refund,
  value,
  first_name,
  last_name,
  postal_code,
  tax,
  affiliation,
  email_address,
  cart,
  coupon,
}: {
  value: number;
  refund?: boolean;
  first_name: string;
  last_name: string;
  postal_code: string;
  affiliation: string;
  tax: number;
  coupon?: string;
  email_address: string;
  cart: { [tixId: string]: EventCartProps };
}) => {
  if (window) {
    window.gtag?.('event', refund ? 'refund' : 'purchase', {
      currency: 'USD',
      value,
      tax,
      affiliation,
      coupon,
      user_data: {
        first_name,
        last_name,
        email_address,
        postal_code,
      },
      items: cart,
    });
  }
};
